var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.tableData,
      "header-cell-style": {
        background: 'rgba(212, 216, 226, 0.2)'
      },
      "empty-text": 'No Data Available',
      "height": "45vh"
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Code Name",
      "prop": "code"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Service Type",
      "prop": "service_type"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Expiration"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('p', [_vm._v(" " + _vm._s(_vm.moment(scope.row.expiry_date).format('L')) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Terms"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('p', [_vm._v(" " + _vm._s(_vm.getPercent(scope.row.percentage)) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Limits",
      "prop": "limit"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Action",
      "width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.status == 1 ? _c('button', {
          staticClass: "disapprove",
          on: {
            "click": function ($event) {
              return _vm.toggleProcomode(scope, 0);
            }
          }
        }, [_vm._v(" Disable ")]) : _c('button', {
          staticClass: "approve",
          on: {
            "click": function ($event) {
              return _vm.toggleProcomode(scope, 1);
            }
          }
        }, [_vm._v(" Enable ")])];
      }
    }])
  })], 1), _c('ActionModal', {
    ref: "actionModal",
    attrs: {
      "payload": _vm.payload
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };