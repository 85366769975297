var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('tableComponent', {
    attrs: {
      "promoType": 'inactivePromoCodes'
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };