import $ from 'jquery';
import store from '@/state/store.js';
export default {
  props: {
    promoType: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      payload: {
        type: ''
      }
    };
  },
  computed: {
    tableData() {
      return store.getters['admin/promocodes/' + this.promoType];
    }
  },
  methods: {
    getPercent(per) {
      return `${per * 100}% off`;
    },
    openActionModal() {
      let element = this.$refs.actionModal.$el;
      $(element).modal('show');
    },
    toggleProcomode(message, status) {
      this.payload = {
        message: status == 0 ? 'Are you sure you want to disable this promo code?' : 'Are you sure you want to enable this promo code?',
        action: 'togglePromoCode',
        actionMessage: status == 1 ? 'Enable' : 'Disable',
        data: message,
        status: status
      };
      this.openActionModal();
    }
  }
};